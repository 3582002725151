.products-page {
    padding: 2rem;
  
    .products-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
  
      .product-item {
        background: #f5f5f5;
        padding: 1rem;
        border-radius: 10px;
        text-align: center;
        img {
          max-width: 200px;
          border-radius: 10px;
        }
        h3 {
          margin: 1rem 0;
        }
      }
    }
  }

  .product-link {
    font-family: sans-serif;
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 12px;
    background-color: #e9f5ff;
    border-radius: 4px;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
  
    &:hover {
      background-color: #007bff;
      color: white;
      text-decoration: none;
    }
  }
  
  